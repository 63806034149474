/*	Oakuery version 0.1.2
 *	a.k.a oQuery, OMFGquery or simply O.
 *	– a lightweight js library by Oakwood
 */

// O Library constructor
var O = function(item, context){
	var self = this;

	if (window === this) {
		// wrong scope, raise self awareness
		return new O(item, context);

	} else {
		// correct scope
		var els;
		if (typeof item === 'string' && item.substr(0,1) === '#' && item.indexOf(',') < 0) {
			// select single item by id
			context = context || document;
			var el = context.getElementById(item.substr(1));
			els = [];
			if(el) els.push(el);
		} else if (typeof item === 'string') {
			// select by css selector
			context = context || document;
			els = context.querySelectorAll(item);

		} else if (typeof item === 'object') {
			// list of elements (if single, put in array)
			// TODO: selects have a length and fuck this up... should do a smarter check
			els = item.length && item.tagName != 'SELECT' ? item : [item];

		} else if (typeof item === 'function') {
			// function, run on dom load
			document.addEventListener('DOMContentLoaded', item);

			// make utils available externally (TODO: probably not the best way to do this....?)
			window.O.Utils = new O();

			// do some feature tests
			window.O.Tests = {
				touch: !!(('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch),
				transition: O.Utils.cssSupport('transition'),
				transform: O.Utils.cssSupport('transform'),
				matchMedia: !!(window.matchMedia || window.msMatchMedia)
			};
			return;

		} else if (typeof item === 'undefined') {
			// undefined, create object and shut up
			return;

		} else {
			// unknown
			self.error('unknown item type: ' + typeof item);
			return;
		}


		// Item constructor
		var Oakuery = function(els) {
			for (var i = 0; i < els.length; i++ ) {
				this[i] = els[i];
			}
			this.length = els.length;
		};


		// Item methods
		Oakuery.prototype = {
			// Iterators and stuff
			each: function (callback) {
				for (var i = 0, len = this.length; i < len; i++) {
					// run callback
					var feedback = callback(this[i], i);

					// if callback returns false, break loop
					if(feedback === false) break;
				}
			},
			eq: function (i) {
				return new O(this[i]);
			},
			index: function() {
				if (this.length > 1) self.error(100);

				var siblings = this[0].parentElement.children;
				for (var i = 0, len = siblings.length; i < len; i++) {
					if (siblings[i] === this[0]) return i;
				}
			},

			// Event listeners
			on: function (events, callback, once) {
				// detach if once is true
				if(once) {
					var orig = callback;
					callback = function(e){
						O(this).off(events, callback);
						orig(e);
					};
				}

				// split events into array and add listeners for each
				var events_array = events.split(' ');
				this.each(function (el) {
					for (var i = 0, len = events_array.length; i < len; i++) {
						el.addEventListener(events_array[i], callback, false);
					}
				});
				return this;
			},
			one: function (events, callback) {
				return this.on(events, callback, true);
			},
			off: function(events, callback) {
				// split events into array and remove listeners for each
				var events_array = events.split(' ');
				this.each(function (el) {
					for (var i = 0, len = events_array.length; i < len; i++) {
						el.removeEventListener(events_array[i], callback, false);
					}
				});
				return this;
			},

			// DOM manipulation
			clone: function() {
				var clones = [];
				this.each(function (el, i) {
					clones[i] = el.cloneNode(true);
				});
				return O(clones);
			},
			append: function(content){
				if (!content.length) content = [content];
				this.each(function (el) {
					for (var i = 0, len = content.length; i < len; i++) {
						el.appendChild(content[i]);
					}
				});
			},
			empty: function () {
				this.each(function (el) {
					while(el.firstChild) {
						el.removeChild(el.firstChild);
					}
				});
			},
			remove: function() {
				this.each(function (el) {
					el.parentElement.removeChild(el);
				});
				return;
			},
			wrap: function(wrapper) {
				for (var i = this.length - 1; i >= 0; i--) {
					var child = (i > 0) ? wrapper.cloneNode(true) : wrapper,
						el = this[i],
						parent = el.parentNode,
						sibling = el.nextSibling;

					child.appendChild(el);

					if (sibling) {
						parent.insertBefore(child, sibling);
					} else {
						parent.appendChild(child);
					}
				}
				return this;
			},

			// CSS and style
			css: function (prop, val) {
				if (val) {
					// set single property
					this.each(function (el) {
						el.style[self.toCamelCase(prop)] = val;
					});
					return this;

				} else if (typeof prop == 'object') {
					// set multiple poperties
					this.each(function (el) {
						for(i in prop){
							el.style[self.toCamelCase(i)] = prop[i];
						}
					});
					return this;

				} else {
					// get property
					if (this.length > 1) self.error(100);

					var val = this[0].style[self.toCamelCase(prop)];
					if (!val) val = window.getComputedStyle(this[0])[self.toCamelCase(prop)];

					return val;
				}
			},
			cssPrefix: function(prop, val){
				var vendors = ['-moz-','-webkit-','-o-','-ms-','-khtml-',''];
				this.each(function (el, i) {
					for (var i=0, l=vendors.length; i<l; i++)
						el.style[self.toCamelCase(vendors[i] + prop)] = val;
				});
				return this;
			},
			addClass: function (cls) {
				this.each(function (el, i) {
					var classes = cls.split(' ');
					for (var i = 0, len = classes.length; i < len; i++) {
						el.classList.add(classes[i]);
					}
				});
				return this;
			},
			removeClass: function (cls) {
				this.each(function (el, i) {
					var classes = cls.split(' ');
					for (var i = 0, len = classes.length; i < len; i++) {
						el.classList.remove(cls);
					}
				});
				return this;
			},
			toggleClass: function (cls) {
				this.each(function (el, i) {
					var classes = cls.split(' ');
					for (var i = 0, len = classes.length; i < len; i++) {
						el.classList.toggle(cls);
					}
				});
				return this;
			},
			hasClass: function (cls) {
				if (this.length > 1) self.error(100);
				return this[0].classList.contains(cls);
			},
			hide: function () {
				this.each(function (el, i) {
					el.style.display = 'none';
				});
				return this;
			},
			show: function () {
				this.each(function (el, i) {
					el.style.display = 'block';
				});
				return this;
			},
			height: function (val) {
				if (val) {
					// set height
					if(typeof val === 'number') val += 'px';

					this.each(function (el, i) {
						el.style.height = val;
					});
					return this;
				} else {
					// get height
					if (this.length > 1) self.error(100);
					return this[0].offsetHeight;
				}
			},
			width: function (val) {
				if (val) {
					// set width
					if(typeof val === 'number') val += 'px';

					this.each(function (el, i) {
						el.style.width = val;
					});
					return this;
				} else {
					// get width
					if (this.length > 1) self.error(100);
					return this[0].offsetWidth;
				}
			},
			offset: function () {
				// get element position relative to document
				if (this.length > 1) self.error(100);
				return {
					top: this[0].getBoundingClientRect().top + window.pageYOffset,
					left: this[0].getBoundingClientRect().left + window.pageXOffset
				}
			},

			// animation
			slideDown: function(duration, callback) {
				var scope = this;

				if(typeof duration == 'function') callback = duration;

				// convert duration ms to s
				duration = typeof duration == "number" ? duration : 400;

				this.each(function (el, i) {
					if(O.Tests.transition) {
						// prepare css transition

						// does it already have a transition property?
						var transition = getComputedStyle(el)[O.Tests.transition + 'Duration'];
						if(transition == '0s') {
							// adding transition property
							scope.eq(i).cssPrefix('transition', 'height ' + duration + 'ms');
						} else {
							var prop = getComputedStyle(el)[O.Tests.transition + 'Property'];
							if(prop != 'height' && prop != 'all') {
								self.error(200);
							}
						}
					}

					// get height. if it doesn't have any, set to 0
					var domStart = getComputedStyle(el)['height'];
					if (domStart == 'auto') {
						scope.eq(i).css('height', '0px');
						domStart = '0px';
					}

					// get full height (clone and remove)
					var clone = O(el.cloneNode(true));
					el.parentElement.appendChild(clone[0]);
					var height = clone.css({
						position: 'absolute',
						display: 'inherit',
						visibility: 'hidden',
						width: '100%',
						height: 'auto'
					}).height();
					clone.remove();

					// get ready to animate
					el.style.display = 'inherit';
					el.style.overflow = 'hidden';

					if(O.Tests.transition) {
						// perform css transition
						var h = el.offsetHeight; // trig reflow
						el.style.height = height + 'px';

						scope.eq(i).one('webkitTransitionEnd oTransitionEnd transitionend', function(){
							// temporarily disable transition to avoid unwanted animation
							scope.eq(i).cssPrefix('transition', 'none');

							el.style.height = 'auto';
							el.style.overflow = '';

							var h = el.offsetHeight; // trig reflow
							// re-enable transition and run callback
							(transition == '0s') ? scope.eq(i).cssPrefix('transition', 'height ' + duration + 'ms') : scope.eq(i).cssPrefix('transition', '');
							if(callback) callback();
						});

					} else {
						// perform dom animation (looking at you IE9)
						O(el).domAnimate('height', domStart, height + 'px', duration, function(){
							el.style.height = 'auto';
							el.style.overflow = '';
							if(callback) callback();
						});
					}


				});

				return this;
			},
			slideUp: function(duration, callback) {
				var scope = this;

				if(typeof duration == 'function') callback = duration;

				// convert duration ms to s
				duration = typeof duration == "number" ? duration : 400;

				// alert(duration);

				this.each(function (el, i) {
					// set to current height (temporarily disable transition)
					if(O.Tests.transition) scope.eq(i).cssPrefix('transition', 'none');

					var height = scope.eq(i).height();
					el.style.overflow = 'hidden';
					el.style.height = height + 'px';

					if(O.Tests.transition) {
						// don't really know why, but this delay is needed...
						// otherwise it doesn't detect css applied transitions properly.
						// the reflow trick used in the slideDown function doesn't work here.
						setTimeout(function(){
							// re-enable transition
							scope.eq(i).cssPrefix('transition', '');

							// does it have a transition property?
							var transition = getComputedStyle(el)[O.Tests.transition + 'Duration'];
							if(transition == '0s') {
								// adding transition property
								scope.eq(i).cssPrefix('transition', 'height ' + duration + 'ms');
							} else {
								var prop = getComputedStyle(el)[O.Tests.transition + 'Property'];
								if(prop != 'height' && prop != 'all') {
									self.error(200, el);
								}
							}

							// reset and run callback
							el.style.height = '0px';
							if(callback) scope.eq(i).one('webkitTransitionEnd oTransitionEnd transitionend', callback);
						}, 10);

					} else {
						// IE animation
						O(el).domAnimate('height', height + 'px', 0, duration, callback);
					}


				});

				return this;
			},
			slideToggle: function(duration, callback) {
				var scope = this;
				this.each(function (el, i) {
					if (scope.eq(i).css('display') != 'none' && scope.eq(i).css('height') != '0px') {
						scope.eq(i).slideUp(duration, callback);
					} else {
						scope.eq(i).slideDown(duration, callback);
					}
				});

				return this;
			},
			domAnimate: function(prop, from, to, duration, callback) {
				if (typeof duration == 'function') {
					callback = duration;
					duration = null;
				}
				if(!duration) duration = 400;

				this.each(function (el) {
					// get values
					var start = parseInt(from, 10),
						end = parseInt(to, 10),
						unit = typeof from == 'number' ? null : from.replace(start, '');

						prop = self.toCamelCase(prop);

						// tween that sucker
						self.tween(start, end, duration, function(curr){
							el.style[prop] = curr + unit;
						}, callback);
				});
				return this;
			},

			// Ajax / network
			load: function(url, callback){
				var scope = this;
				self.get(url, function(){
					scope[0].innerHTML = this.responseText;
					if(callback) callback();
				});
				return scope;
			},

			// sample function
			apa: function (val) {
				this.each(function (el, i) {
					console.log(el, i, val)
				});
				return this;
			}
		};

		// create and return
		return new Oakuery(els);
	}
};

// O methods available both internally in self and externally in O.Utils.
O.prototype = {
	// o stuff
	error: function (what) {
		if(typeof what === 'number') {
			// error code
			if (what === 100) what = 'More than one item, returning value for first item only';
			else if(what === 200) what = 'Element already has a transition that is not height or all!';
		}

		console.error('O(MFG): ' + what);
	},

	// string manipulation
	toCamelCase: function (str) {
		return str.toLowerCase().replace(/(\-[a-z])/g, function($1){return $1.toUpperCase().replace('-','');});
	},

	// ajax get url
	get: function(url, callback){
		var oReq = new XMLHttpRequest();
		if(callback) oReq.onload = callback;
		oReq.open("get", url, true);
		oReq.send();
	},

	// ajax post with url encoded data
	post: function(url, data, callback){
		var oReq = new XMLHttpRequest();
		if(callback) oReq.onload = callback;
		oReq.open("post", url, true);
		oReq.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
		oReq.send(data);
	},

	// animation
	tween: function(start, end, duration, func, callback) {
		var starttime = new Date().getTime(),
			difference = end - start;
		OW.stopTween = false;

		function easeOut(t, b, c, d) {
			return -c/2 * (Math.cos(Math.PI*t/d) - 1) + b;
		}

		// loop it
		function loop(){
			var passedTime = new Date().getTime() - starttime;
			var currentVal = easeOut(passedTime, start, difference, duration);
			if (passedTime > duration) currentVal = end;

			func(currentVal);

			// keep on looping?
			if(!OW.stopTween && currentVal != end) {
				requestAnimationFrame(loop);
			} else if(callback) {
				callback();
			}

		}
		requestAnimationFrame(loop);
	},

	softScroll: function(to, duration, callback){
		if(typeof duration == 'function') callback = duration;
		if(typeof duration != 'number') duration = 1400;

		var maxScroll = document.height - window.innerHeight;
		if(to > maxScroll) to = maxScroll;

		O.Utils.tween(window.pageYOffset, to, duration, function(val){
			window.scrollTo(0, val);
		}, callback ? callback : null);
	},

	// test support for css property
	cssSupport: function(p) {
		var b = document.body || document.documentElement,
		s = b.style;

		// No css support detected
		if(typeof s == 'undefined') { return false; }

		// Tests for standard prop
		if(typeof s[p] == 'string') { return p; }

		// Tests for vendor specific prop
		v = ['Moz', 'Webkit', 'Khtml', 'O', 'ms', 'Icab'],
		p = p.charAt(0).toUpperCase() + p.substr(1);

		for(var i=0; i<v.length; i++) {
			if(typeof s[v[i] + p] == 'string') { return (v[i] + p); }
		}

		return false;
	},

	// Cookies
	createCookie: function(name,value,days) {
		if (days) {
			var date = new Date();
			date.setTime(date.getTime()+(days*24*60*60*1000));
			var expires = "; expires="+date.toGMTString();
		}
		else var expires = "";
		document.cookie = name+"="+value+expires+"; path=/";
	},
	readCookie: function(name) {
		var nameEQ = name + "=";
		var ca = document.cookie.split(';');
		for(var i=0;i < ca.length;i++) {
			var c = ca[i];
			while (c.charAt(0)==' ') c = c.substring(1,c.length);
			if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
		}
		return null;
	},
	eraseCookie: function(name) {
		createCookie(name,"",-1);
	}
};
