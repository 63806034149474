/* TUTIMA by Oakwood */
var OW = OW || {};

/* "GLOBALS" */
OW.Global = {

    // settings
    animationSpeed: 800,		// animation duration base
    mobileBreakpoint: 640,

    // cache some handy values
    winHeight: null,
    winWidth: null,
    rootUrl: document.querySelector('.site-header .logo').pathname,
    rootTitle: (document.querySelector('.main-sections')) ? document.querySelector('.main-sections').dataset.homeTitle : '',
    headerHeightOpen: 300,
    headerHeightClosed: 80,

    // Oakuery objects
    body: O('body'),
    topMenu: O('.meta-menu'),
    langshipMenu: O('.langship-menu'),
    header: O('.site-header'),
    headerContent: O('.header-content'),
    sectionContainer: O('.main-sections'),
    sections: O('.section-wrapper'),

    // flags
    responsiveState: null,		// store current state (mobile or desktop)
    desktopLoaded: false,		// set to true after first load
    mobileLoaded: false		// set to true after first load

};

// background fader
OW.fadeBackground = function (img) {
    var container = document.getElementById('fade-bg');

    if (img) {
        // set custom bg to new div
        var div = document.createElement('div');
        div.style.backgroundImage = 'url(' + img + ')';
        container.appendChild(div);

        if (O.Tests.transition) {
            // css transition
            var h = div.offsetHeight; // trig reflow
            div.style.opacity = 1;

            // remove siblings after animation
            O(div).one('webkitTransitionEnd oTransitionEnd transitionend', function () {
                var siblings = div.parentNode.children;
                for (var i = 0, len = siblings.length - 1; i < len; i++) {
                    siblings[i].parentNode.removeChild(siblings[i]);
                }
            });
        } else {
            // ie transition
            O(div).domAnimate('opacity', 0, 1, function () {
                var siblings = div.parentNode.children;
                for (var i = 0, len = siblings.length - 1; i < len; i++) {
                    siblings[i].parentNode.removeChild(siblings[i]);
                }
            });
        }

    } else {
        // remove custom bg
        var children = container.children,
            last = children[children.length - 1];

        if (!last) return;

        // fade out and empty
        if (O.Tests.transition) {
            last.style.opacity = 0;
            O(last).one('webkitTransitionEnd oTransitionEnd transitionend', function () {
                O(container).empty();
            });
        } else {
            O(last).domAnimate('opacity', 1, 0, function () {
                O(container).empty();
            });
        }
    }
};

// top menu toggler
OW.topMenu = function () {
    // toggle meta menu
    O('#toggle-topmenu').on('click', function (e) {

        e.preventDefault();

        // close language and shipping menu
        OW.Global.langshipMenu.slideUp();

        OW.Global.topMenu.slideToggle();
        this.classList.toggle('open');
        if (O.Tests.transition) {
            if (OW.updateStickies) O(this).on('webkitTransitionEnd oTransitionEnd transitionend', OW.updateStickies);
        } else {
            if (OW.updateStickies) OW.updateStickies();
        }
    });

    // toggle language and shipping
    O('#toggle-langship').on('click', function (e) {
        e.preventDefault();

        if (OW.Global.responsiveState == 'mobile' && OW.Global.topMenu.height()) {
            // for mobile, close meta menu
            OW.Global.topMenu.slideUp();
        } else if (OW.Global.responsiveState == 'desktop') {
            // for desktop, set right position
            var right = window.innerWidth - this.getBoundingClientRect().right - 30;
            OW.Global.langshipMenu.css('right', right + 'px');
        }

        OW.Global.langshipMenu.slideToggle(OW.Global.animationSpeed / 2);

    });

    // close language and shipping
    OW.Global.langshipMenu.on('click', function (e) {
        if (e.target.className == 'close-x') {
            e.preventDefault();
            OW.Global.langshipMenu.slideUp(OW.Global.animationSpeed / 2);
        }
    });

    // hacky redirect solution to get translated paths to work
    O('#language-selector').on('change', function (e) {
        document.body.style.cursor = 'wait';

        var self = this,
            lang = this.options[this.selectedIndex].dataset.lang;

        O.Utils.get(window.getAddress(), function () {
            var temp = document.createElement('div');
            O.Utils.createCookie('preferred_lang', lang, 360);

            temp.innerHTML = this.responseText;
            var url = temp.querySelector('#language-selector [data-lang=' + lang + ']').value;

            window.location = url;
        });
    });

    O('#shipping-selector').on('change', function (e) {
        document.body.style.cursor = 'wait';

        var self = this,
            id = this.options[this.selectedIndex].value;

        O.Utils.post('/selection/', 'only_update=1&ajax=1&country_top=1&cou_id=' + id, function (data) {
            window.location.reload();
        });
    });
};

// mobile meta menu
OW.setupMobileMenu = function () {
    O('.menu-group .title').on('click', function (e) {
        e.stopPropagation();
        O(this.nextElementSibling).slideToggle(OW.Global.animationSpeed / 2);
    });
};

// clone meta menu to footer
OW.setupFooter = function () {
    $ = jQuery;
    $('.site-footer').html('');
    O('.site-footer').append(
        O('.meta-menu').clone().removeClass('top-menu')
    );
};

// section navigation via click and history events
OW.sectionNavigation = function () {
    var self = this;

    // stupid ie 9 fix
    if (OW.Global.rootUrl.charAt(0) != '/') OW.Global.rootUrl = '/' + OW.Global.rootUrl;

    // hijack sticky clicks
    O('.main-sections').on('click', function (e) {

        // was it a closed sticky? (and not cmd-clicked)
        if (!e.metaKey && e.target.parentElement.classList.contains('section-sticky') && e.target.parentElement.parentElement.dataset.open != 'true') {
            // stop it
            e.preventDefault();

            var section = e.target.parentElement.parentElement;

            // store current uri and which section it was in
            self.prevUri = window.location.pathname;

            // is it currently open?
            if (section.dataset.open != 'true') {
                // no, open it
                var gotoUrl = e.target.pathname;
                if (gotoUrl.charAt(0) != '/') gotoUrl = '/' + gotoUrl;

                window.setAddress(gotoUrl);
            } else {
                // yes, go home
                window.setAddress(OW.Global.rootUrl);
            }
        }
    });

    // todo: listen for root url link clicks. todo: maybe add breadcrumb root links?
    O('.logo, .home-link').on('click', function (e) {
        if (!e.metaKey && OW.Global.sectionOpen) {
            e.preventDefault();
            window.setAddress(OW.Global.rootUrl);
        }
    });


    // handle click and history events
    window.onAddressChange = function (newUrl) {
        // close any open section
        O('.section-wrapper.open').each(function (el) {
            O(el).slideUp(function () {
                if (OW.updateStickies) OW.updateStickies();
            });
            el.classList.remove('open');
            el.dataset.open = false;
        });

        // debugger;

        // close meta menu and lang menu
        OW.Global.topMenu.slideUp();
        OW.Global.langshipMenu.slideUp();

        // update sticky menus
        if (OW.updateStickies) OW.updateStickies();

        if (newUrl == OW.Global.rootUrl) {
            // back to root, close all sections

            // add home video class
            if (OW.Global.body.hasClass('has-background-video')) OW.Global.body.addClass('background-video-open');

            if (O.Tests.transition) {
                // css transition
                OW.Global.body.removeClass('section-open');
                OW.fadeBackground();
            } else if (OW.Global.sectionOpen) {
                // animate some for the IE9ers
                OW.Global.header.domAnimate('height', OW.Global.headerHeightClosed + 'px', OW.Global.headerHeightOpen, function () {
                    OW.Global.body.removeClass('section-open');
                    if (OW.updateStickies) OW.updateStickies();
                });
                OW.fadeBackground();
            }

            OW.Global.sectionContainer[0].style.minHeight = '';
            document.title = OW.Global.rootTitle;
            if (OW.Global.responsiveState == 'mobile') OW.Global.headerContent.slideDown();

            OW.Global.sectionOpen = false;

        } else {
            // find section, open it
            OW.Global.sections.each(function (el, i) {
                // see if section root or currently loaded content fits
                var sectionRoot = el.firstElementChild.firstElementChild.pathname;
                if (sectionRoot.charAt(0) != '/') sectionRoot = '/' + sectionRoot; // ie9 fix

                if (sectionRoot == newUrl || el.dataset.loaded == newUrl || el.dataset.cached == newUrl) {
                    if (newUrl == el.dataset.loaded) {
                        // content already loaded, show instantly

                        if (newUrl == sectionRoot) {
                            // get data from section root
                            // must use setAttr because of crappy crappy IE9
                            el.setAttribute('data-title', el.dataset.rootTitle);
                            el.setAttribute('data-background', el.dataset.rootBackground);
                        }

                        OW.Global.sections.eq(i).slideDown(function () {
                            el.classList.add('open');
                            if (OW.updateStickies) OW.updateStickies();
                        });

                    } else if (newUrl == el.dataset.cached) {
                        // cached content, swap loaded and cached paths
                        el.dataset.cached = el.dataset.loaded;
                        el.dataset.loaded = newUrl;

                        // swap titles...
                        var cachedTitle = el.dataset.title;
                        el.dataset.title = el.dataset.cachedTitle;
                        el.dataset.cachedTitle = cachedTitle;

                        // and backgrounds...
                        var cachedBg = el.dataset.background;
                        el.dataset.background = el.dataset.cachedBackground;
                        el.dataset.cachedBackground = cachedBg;

                        // and cached element...
                        var newCache = el.querySelector('.main-content');
                        el.querySelector('.cached-content').className = 'main-content';
                        newCache.className = 'cached-content';

                        OW.Global.sections.eq(i).slideDown(function () {
                            el.classList.add('open');
                            if (OW.updateStickies) OW.updateStickies();

                        });

                    } else if (newUrl == sectionRoot) {
                        // load section root via ajax

                        // move old content, if any, to cache
                        if (el.dataset.loaded !== '') { // ie9 evaluates empty string as true here?
                            el.setAttribute('data-cached', el.dataset.loaded);
                            el.setAttribute('data-cached-title', el.dataset.title);
                            el.setAttribute('data-cached-background', el.dataset.background);
                            var content = el.querySelector('.main-content');
                            content.className = 'cached-content';
                        }

                        // set data and show loading feedback
                        el.dataset.title = el.dataset.rootTitle;
                        el.dataset.background = el.dataset.rootBackground;
                        el.dataset.loaded = newUrl;
                        document.body.style.cursor = 'wait';

                        // load new content
                        O.Utils.get(newUrl + '?ajax_content=1', function () {
                            // loaded, inject it
                            var div = document.createElement('div');
                            div.innerHTML = this.responseText;
                            div.className = 'main-content';
                            el.appendChild(div);

                            OW.ajaxLoadedSection(i);

                            // ready to show content
                            OW.Global.sections.eq(i).slideDown(function () {
                                el.classList.add('open');
                                if (OW.updateStickies) OW.updateStickies();

                            });
                            document.body.style.cursor = 'auto';

                        });
                    }

                    // all done
                    el.setAttribute('data-open', true);
                    document.title = el.dataset.title;
                    OW.fadeBackground(el.dataset.background);

                    // break the loop, you're done
                    return false;

                } else if (OW.Global.sections.length == (i + 1)) {
                    // no hit found! should ONLY happen when a hashbang link (from IE9)
                    // is opened on a modern browser and the path isn't one of the section roots
                    var trackingURL = '?_ga=2.111698946.784615213.1512633714-1928723598.1511340416';

                    if (window.location.href.indexOf(trackingURL) > -1) {
                        console.log('no mans land: ' + newUrl);
                        window.location = newUrl;
                    } else {
                        window.location = '/de';
                    }
                }
            });

            // remove home video class
            if (OW.Global.body.hasClass('has-background-video')) OW.Global.body.removeClass('background-video-open');

            // animate some for the IE9ers
            if (!O.Tests.transition && !OW.Global.sectionOpen) {
                OW.Global.header.height(OW.Global.headerHeightOpen);
                OW.Global.header.domAnimate('height', OW.Global.headerHeightOpen + 'px', OW.Global.headerHeightClosed);
            }

            // set min-height to make footer stick to bottom of browser
            OW.setStickyFooter();

            O.Utils.softScroll(0, function () {
                console.log('scrollat klart');
                if (OW.updateStickies) OW.updateStickies();
            });
            if (OW.Global.responsiveState == 'mobile') OW.Global.headerContent.slideUp();

            // set body class
            OW.Global.body.addClass('section-open');
            OW.Global.sectionOpen = true;
        }

        // Track the page (needs to be in the bottom so we get the correct title)
        if (typeof _gaq != 'undefined') _gaq.push(['_trackPageview']);
    };

    // temp fix: retrigger path when opening hashbang url on html5 browser and vice versa
    // this used to work out of the box with URL.Address but not anymore :/
    if (window.getAddress() != window.location.pathname) window.setAddress(window.getAddress());
};

// sticky headers (warning, a LOT of hacky stuff in here...)
OW.stickyNavigation = function () {
    OW.fixedAbovePosition = 0;
    OW.fixedAbove = [];
    OW.Stickies = [];

    var lastScrollY = 0,
        lastAnimationY = -1,
        lastDiff = 0,
        ticking = false,
        stickyHeight = (document.querySelector('.section-sticky')) ? document.querySelector('.section-sticky').offsetHeight : 0,
        heightBreak = 600,
        pageWrap = document.querySelector('.site-wrapper'),
        pageHeight = pageWrap.offsetHeight,
        desktopVersion = !O.Tests.touch,
        tabOpen = false,
        tabStop = false;

    // store all stickies in array
    O('.section-sticky').each(function (el, i) {
        OW.Stickies[i] = {
            el: el,
            offset: O(el).offset().top,
            position: false
        };
    });

    // bottom tab
    var tab = document.createElement('div');
    tab.className = 'sticky-tab';
    if (OW.Global.sectionContainer[0]) {
        OW.Global.sectionContainer[0].appendChild(tab);
    }

    O(tab).on('mouseover', function () {
        // fold up bottom stickies
        console.log('visa menurkan', tabStop);

        tab.classList.remove('visible');

        for (var i = tabStop, len = OW.Stickies.length; i < len; i++) {
            var botPos = stickyHeight * (len - i - 1);
            OW.Stickies[i].el.classList.add('fixed');
            OW.Stickies[i].el.style.bottom = botPos + 'px';
        }

        var close = function (e) {
            var parent = e.target.parentElement;

            // if not a menu option; close
            if (!(parent.classList.contains('section-sticky') || parent.classList.contains('section-wrapper'))) {
                // close it
                tab.classList.add('visible');

                for (var i = tabStop, len = OW.Stickies.length; i < len; i++) {
                    OW.Stickies[i].el.classList.remove('fixed');
                    OW.Stickies[i].el.style.bottom = '0px';
                }

                // stop listening
                O(window).off('mouseover', close);
            }
        };

        setTimeout(function () {
            O(window).on('mouseover', close);
        }, 100);
    });

    // update offsets
    function updateStickyOffsets() {
        OW.fixedAbove = [];

        for (var i = 0, len = OW.Stickies.length; i < len; i++) {
            OW.Stickies[i].position = false;
            OW.Stickies[i].offset = OW.Stickies[i].el.parentNode.getBoundingClientRect().top;
            pageHeight = pageWrap.offsetHeight;
        }

        // reset tab
        tab.classList.remove('visible');
        tabOpen = false;
    }

    // check and set sticky state
    function sticky(obj, i, lastScrollY) {
        var winHeight = window.innerHeight,
            passedTop = lastScrollY > obj.offset - OW.fixedAbovePosition,
            passedBottom = OW.Stickies[i + 1] && lastScrollY >= OW.Stickies[i + 1].offset - stickyHeight,
            belowView = i > 0 && lastScrollY + winHeight - (OW.Stickies.length - i) * (stickyHeight) < obj.offset,
            prev, top, p;

        if (obj.position !== 'fixed' && !OW.fixedAbovePosition && passedTop && !passedBottom) {
            console.log('fix to top', i);

            obj.el.classList.add('fixed');
            obj.position = 'fixed';
            obj.el.style.top = 0;
            obj.el.style[O.Tests.transform] = '';
            obj.el.style.bottom = 'auto';

            // and fix previous siblings
            if (i) {
                OW.fixedAbove[0] = obj;

                for (p = 0; p < i; p++) {
                    prev = OW.Stickies[p];
                    top = (i - p) * -(stickyHeight);

                    OW.fixedAbove[p + 1] = prev;

                    prev.el.classList.add('fixed');
                    prev.el.style.top = top + 'px';
                    prev.el.style.bottom = 'auto';
                }
            }

            // display bottom menu tab?
            if (winHeight < heightBreak && !tabOpen && OW.Stickies[i + 1]) {
                tab.classList.add('visible');
                tabOpen = true;

                // store index of following sticky to get offset
                tabStop = i + 1;
            }

        } else if (OW.Global.sectionOpen && obj.position !== 'fixed' && winHeight > heightBreak && belowView) {
            console.log('fix to bottom', i);

            obj.el.classList.add('fixed');
            obj.position = 'fixed';
            obj.el.style.top = 'auto';
            obj.el.style.bottom = (OW.Stickies.length - i - 1) * (stickyHeight) + 'px';

        } else if (obj.position !== 'absolute' && (passedBottom || (belowView && winHeight < heightBreak))) {
            console.log('unstick to bottom', i);

            obj.el.classList.remove('fixed');
            obj.position = 'absolute';
            obj.el.style.top = 'auto';
            obj.el.style.bottom = '0';

        } else if (obj.position !== 'absolute' && !passedTop && !belowView) {
            console.log('unstick to top', i);

            obj.el.classList.remove('fixed');
            obj.position = 'absolute';
            obj.el.style.top = '0';
            obj.el.style[O.Tests.transform] = '';
            obj.el.style.bottom = 'auto';

            // and unfix previous siblings
            if (i) {
                for (p = 0; p < i; p++) {
                    prev = OW.Stickies[p];

                    // only if they are out of view
                    if (prev.position == 'absolute') {
                        console.log('prev', prev);

                        prev.el.classList.remove('fixed');
                        prev.el.style[O.Tests.transform] = '';
                        prev.el.style.top = 0;
                        prev.el.style.bottom = 'auto';
                        OW.fixedAbove = [];
                    }
                }
            }
        }
    }

    // animation updater
    function update() {
        var diff = lastAnimationY - lastScrollY,
            direction = diff > 0 ? 'up' : 'down',
            i;

        if (lastScrollY + OW.Global.winHeight < pageHeight) {
            // fixed scroll down menu
            var fixedSpan = (OW.fixedAbove.length - 1) * (stickyHeight);

            if (
                (OW.fixedAbove.length && diff > 0 && OW.fixedAbovePosition <= fixedSpan) ||
                (OW.fixedAbovePosition && diff < 0)
            ) {
                OW.fixedAbovePosition = OW.fixedAbovePosition + diff;

                // limit new position
                if (OW.fixedAbovePosition > fixedSpan) OW.fixedAbovePosition = fixedSpan;
                if (OW.fixedAbovePosition < 0) OW.fixedAbovePosition = 0;

                for (i = 0, len = OW.fixedAbove.length; i < len; i++) {
                    OW.fixedAbove[i].el.style[O.Tests.transform] = 'translateY(' + OW.fixedAbovePosition + 'px)';
                }
            }
        }

        // menu-tab
        if (tabOpen && pageYOffset + window.innerHeight >= OW.Stickies[tabStop].offset) {
            tab.classList.remove('visible');
            tabOpen = false;
        } else if (!tabOpen && tabStop && pageYOffset + window.innerHeight < OW.Stickies[tabStop].offset) {
            tab.classList.add('visible');
            tabOpen = true;
        }

        // update "normal" stickies
        for (i = 0, len = OW.Stickies.length; i < len; i++) {
            sticky(OW.Stickies[i], i, lastScrollY);
        }

        lastAnimationY = lastScrollY;
        lastDiff = diff;
        ticking = false;
    }

    // scoll event handler
    function onScroll() {
        lastScrollY = window.pageYOffset;

        if (!ticking) {
            requestAnimationFrame(update);
        }
        ticking = true;
    }

    window.addEventListener('scroll', onScroll, false);
    window.addEventListener('resize', onScroll, false);

    OW.updateStickies = function () {
        console.log('updated stickies');

        updateStickyOffsets();
        update();
    };

    // run once on page load (including images loaded)
    // wtf, let's do it on transitionend too
    // window.onload = function() { OW.updateStickies(); };
    // OW.Global.header.on('webkitTransitionEnd oTransitionEnd transitionend', OW.updateStickies);

    // OW.Stickies = Stickies;
};

/* Watches categories */
OW.setupCategoryList = function () {
    var list = document.querySelector('.watches-categories-list');
    if (list && list.children.length > 4) {
        // set up list width
        var items = list.children.length,
            pages = Math.ceil(items / 4),
            self = this;

        list.style.width = (items / 4 * 100) + '%';
        O(list.children).css('width', 100 / items + '%');

        // create navigation
        var prev = document.createElement('div');
        var next = prev.cloneNode();

        O(prev).addClass('slideshow-arrow prev').on('click', function (e) {
            OW.categoryList.prev();
        });

        O(next).addClass('slideshow-arrow next').on('click', function (e) {
            OW.categoryList.next();
        });

        var container = list.parentElement.parentElement;
        container.appendChild(prev);
        container.appendChild(next);
        container.classList.add('first');

        // create pagination
        var pagination = OW.createPagination(pages);
        container.appendChild(pagination);

        O(pagination).on('click', function (e) {
            OW.categoryList.goTo(O(e.target).index());
        });

        // keyboard nav
        var keys = function (e) {
            switch (e.which) {
                case 37:
                    OW.categoryList.prev();
                    break;
                case 39:
                    OW.categoryList.next();
                    break;
            }
        };

        O(document).on('keydown', keys);

        Hammer(list.parentElement).on("swipeleft swiperight", function (e) {
            if (e.type == 'swiperight') {
                OW.categoryList.prev();
            } else {
                OW.categoryList.next();
            }
        });

        // return functions
        return {
            current: 0,
            pages: pages,
            list: list,
            nav: [next, prev, pagination],
            offset: items % 4,

            next: function () {
                if (this.current < this.pages - 1) this.goTo(this.current + 1);
            },

            prev: function () {
                if (this.current) this.goTo(this.current - 1);
            },

            goTo: function (i) {
                // unset current pagination
                this.nav[2].children[this.current].className = '';

                // update current
                this.current = i;

                // set page
                this.nav[2].children[this.current].className = 'current';

                // if it's first or last page, is there an offset?
                var offsetPer = 0;
                if (this.offset && (i == this.pages - 1)) offsetPer = 100 - (this.offset * 25);

                var newPercent = -(this.current * 100 - offsetPer);

                // set position
                if (O.Tests.transition) {
                    // modern browsers <3
                    list.style.left = newPercent + '%';
                } else {
                    // developers, developers, developers...
                    var currentPercent = list.style.left;
                    if (!currentPercent) currentPercent = '0%';

                    O(list).domAnimate('left', currentPercent, newPercent, 200);
                }

                list.parentElement.parentElement.classList.remove('last');
                list.parentElement.parentElement.classList.remove('first');
                if (!this.current) {
                    // first page
                    list.parentElement.parentElement.classList.add('first');
                } else if (this.current == this.pages - 1) {
                    // last page
                    list.parentElement.parentElement.classList.add('last');
                }
            },

            destroy: function () {
                this.list.removeAttribute('style');
                O(document).off('keydown', OW.categoryList.keys);
                O(OW.categoryList.nav).remove();
                O(this.list.children).css('width', 'auto');
            },

            keys: keys
        };
    }
};

// model list
OW.setupModelList = function () {
    var toggle = O('.watches-model-list .show-all');

    // only run if there is a toggle
    if (toggle.length) {
        // model list toggle
        var isOpen = false;

        toggle.on('click', function (e) {

            e.preventDefault();
            OW.modelList.toggle();
        });

        return {
            isOpen: isOpen,
            list: toggle[0].parentElement,
            rows: toggle[0].parentElement.querySelectorAll('.list-row'),

            toggle: function () {
                var self = this;

                if (!this.isOpen) {
                    // open it
                    O(this.rows).each(function (el, i) {
                        if (OW.Global.responsiveState == 'mobile' && !i) {
                            // run mobile animation on first row only
                            var closedHeight = el.firstElementChild.firstElementChild.offsetHeight;
                            el.style.height = closedHeight + 'px';
                            el.style.minHeight = closedHeight + 'px';
                            el.classList.add('animate');

                            // get height to transition to (use winheight to )
                            var openHeight = el.firstElementChild.offsetHeight,
                                winHeight = window.innerHeight + closedHeight;

                            // listen for transition end
                            O(el).one('webkitTransitionEnd oTransitionEnd transitionend', function () {
                                self.list.classList.remove('closed');
                                el.classList.remove('animate');
                                el.style.height = openHeight + 'px';
                            });

                            // kick off transition
                            el.style.height = (openHeight > winHeight ? winHeight : openHeight) + 'px';

                            return false;

                        } else if (i) {
                            // run desktop animation on each row
                            O(el).slideDown();
                        }
                    });
                    if (OW.Global.responsiveState == 'desktop') this.list.classList.remove('closed');

                } else {
                    // close it
                    var listTop = O(OW.modelList.list).offset().top,
                        close = function () {
                            O(self.rows).each(function (el, i) {
                                if (OW.Global.responsiveState == 'mobile' && !i) {
                                    var openHeight = el.firstElementChild.offsetHeight;
                                    el.style.height = openHeight + 'px';
                                    el.classList.add('animate');

                                    O(el).one('webkitTransitionEnd oTransitionEnd transitionend', function () {
                                        el.classList.remove('animate');
                                    });

                                    var closedHeight = el.firstElementChild.firstElementChild.offsetHeight;
                                    el.style.height = closedHeight + 'px';

                                    return false;

                                } else if (i) {
                                    O(el).slideUp(function () {
                                        el.style.display = 'none';
                                    });
                                }
                            });
                            self.list.classList.add('closed');
                        };

                    // scroll up first?
                    if (window.pageYOffset > listTop) {
                        O.Utils.softScroll(listTop - 30, OW.Global.animationSpeed, close);
                    } else {
                        close();
                    }
                }

                this.isOpen = !this.isOpen;
            },

            reset: function () {
                console.log('reset');
                this.isOpen = false;
                this.list.classList.add('closed');
                O('.list-row, .list-row article', this.list).each(function (el) {
                    el.removeAttribute('style');
                });
            }
        };
    }
};

/* Product page */
OW.setupProductPage = function () {
    O('.image-fader').each(function (el) {
        new OW.imageFader(el);
    });

    O('.slideshow').each(function (el, id) {
        new OW.slideshow(el);
    });

    // scroll from small variation list to big
    O('.product-variations .show-all').on('click', function (e) {
        e.preventDefault();
        O.Utils.softScroll(O('.watches-model-list').offset().top - 30, OW.Global.animationSpeed * 1.5);
    });

    // concierge select menu
    O('.concierge-wrapper select').on('change', function () {
        var option = this.options[this.selectedIndex],
            phone = $(option).data('phone'),
            email = $(option).data('email');

        if (phone) {
            $phone = $('.concierge-phone');
            $phone.attr('href', 'tel:' + phone);
            $phone.text($phone.data('call') + phone);
        }

        if (email) {
            $email = $('.concierge-email');
            $email.attr('href', 'mailto:' + email);
        }
    });
};

OW.shareButtons = function () {
    // load buttons on first hover
    O('.share-module').one('mouseover', function (e) {
        if (e.target.dataset.loaded == 'false') {
            OW.loadSocial();
            e.target.dataset.loaded = true;
        }
    });
};

// load (or reload) social media sdk's
OW.loadSocial = function () {
    // facebook
    if (typeof FB == "undefined") {
        (function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) return;
            js = d.createElement(s);
            js.id = id;
            js.src = "//connect.facebook.net/en_US/all.js#xfbml=1";
            fjs.parentNode.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));
    } else {
        FB.XFBML.parse();
    }

    // twitter
    if (typeof twttr == "undefined") {
        !function (d, s, id) {
            var js, fjs = d.getElementsByTagName(s)[0];
            if (!d.getElementById(id)) {
                js = d.createElement(s);
                js.id = id;
                js.src = "//platform.twitter.com/widgets.js";
                fjs.parentNode.insertBefore(js, fjs);
            }
        }(document, "script", "twitter-wjs");
    } else {
        twttr.widgets.load();
    }

    // pinterest (todo: couldn't find a reload func)
    (function (d) {
        var f = d.getElementsByTagName('SCRIPT')[0], p = d.createElement('SCRIPT');
        p.type = 'text/javascript';
        p.async = true;
        p.src = '//assets.pinterest.com/js/pinit.js';
        f.parentNode.insertBefore(p, f);
    }(document));
};

// fading image slideshow
OW.imageFader = function (el) {
    var self = this,
        images = el.children;

    // are there more than one image?
    if (images.length > 1) {
        var nav = document.createElement('ul');
        nav.className = 'tab-menu';

        // get image titles
        for (var i = 0, len = images.length; i < len; i++) {
            var item = document.createElement('li');
            item.innerHTML = images[i].dataset.fadeTitle;

            // append to nav
            nav.appendChild(item);
        }
        // append nav
        el.appendChild(nav);

        // listen for clicks
        O(nav).on('click', function (e) {
            self.fadeTo(O(e.target).index());
        });
    }

    // create magnifyer (not for touch)
    if (!O.Tests.touch) {
        this.getDimensions = function () {
            this.offsetTop = el.getBoundingClientRect().top;
            this.offsetLeft = el.getBoundingClientRect().left;
            this.elHeight = el.offsetHeight;
            this.elWidth = el.offsetWidth;
            this.imgHeight = self.currentImage.naturalHeight;
            this.imgWidth = self.currentImage.naturalWidth;
        };

        var magnifyer = document.createElement('div');
        magnifyer.className = 'magnifyer';
        el.appendChild(magnifyer);

        O(el).on('mouseover', function (e) {
            // get dimensions
            self.getDimensions();
            magnifyer.style.display = 'block';
        });

        O(el).on('mousemove', function (e) {
            var top = e.clientY - self.offsetTop,
                left = e.clientX - self.offsetLeft,
                topPercent = top / self.elHeight,
                leftPercent = left / self.elWidth;

            if (top < 0 || left < 0 || top > self.elHeight || left > self.elWidth) {
                // outside image, hide magnifyer
                magnifyer.style.display = 'none';

            } else if (self.magnifyerOriginal) {
                // update magnifyer position
                var original = self.magnifyerOriginal;

                var ratio = self.elWidth / self.elHeight;
                var originalRatio = original.width / original.height;
                if (ratio < 1) {
                    hRatio = 1;
                    hRatioDiff = 0;
                    wRatio = ratio;
                    wRatioDiff = originalRatio - ratio;
                }
                else {
                    hRatio = ratio;
                    hRatioDiff = originalRatio - ratio;
                    wRatio = 1;
                    wRatioDiff = 0;
                }

                magnifyer.style.top = top + 'px';
                magnifyer.style.left = left + 'px';
                magnifyer.style.backgroundPosition = -(original.width * (leftPercent * wRatio + wRatioDiff / 2) - 90) + 'px ' + -(original.height * (topPercent * hRatio + hRatioDiff / 2) - 90) + 'px';
            }

        });
        this.magnifyer = magnifyer;
    }

    // image switcher
    this.fadeTo = function (i) {
        // reset currents
        O('.current', el).removeClass('current');

        // set new current
        this.currentImage = images[i];
        images[i].classList.add('current');
        if (nav) nav.children[i].classList.add('current');

        if (!O.Tests.touch) {
            // preload image so we can get it's dimension
            var image = new Image();
            image.onload = function () {
                self.magnifyerOriginal = image;
                self.magnifyer.style.backgroundImage = 'url(' + image.src + ')';
            };

            image.src = images[i].getAttribute('data-original');
        }
    };

    // set to first image
    this.fadeTo(0);

    return this;
};

// pagination list constructor
OW.createPagination = function (items) {
    var el = document.createElement('ul');
    el.className = 'tab-menu';

    for (var i = 1, len = items; i <= len; i++) {
        var item = document.createElement('li');

        // leading zero
        var label = i < 10 ? '0' + i : i;

        item.innerHTML = label;
        el.appendChild(item);
    }

    // set first to current
    el.firstElementChild.className = 'current';

    return el;
};

// touch image slider
OW.slideshow = function (el) {
    var slide = el.querySelector('.slides'),
        slides = slide.children;

    if (!slides.length) {
        console.error('Empty slideshow!?', el);
        return;
    }

    // no captions?
    if (!slide.querySelector('figcaption')) el.classList.add('no-captions');

    // pagination
    var nav = OW.createPagination(slides.length);
    el.insertBefore(nav, slide);

    // initiate slider
    var slider = new Swipe(el, {
        setupCallback: function () {
            // runs on setup and resize
            var firstImg = slides[0].firstElementChild,
                height = firstImg.offsetHeight;

            // set nav position based on img height
            if (height && height > 100) {
                nav.style.top = height + 'px';
            } else {
                firstImg.onload = function () {
                    nav.style.top = firstImg.offsetHeight + 'px';
                };
            }
        },
        callback: function (i) {
            // reset currents
            O('.current', el).removeClass('current');

            // set new current
            nav.children[i].classList.add('current');
        }
    });

    // listen for clicks
    O(nav).on('click', function (e) {
        slider.slide(O(e.target).index());
    });

    // keyboard nav
    var keys = function (e) {
        switch (e.which) {
            case 37:
                slider.prev();
                break;
            case 39:
                slider.next();
                break;
        }
    };

    O(document).on('keydown', keys);
};

// parallax images
OW.parallax = function () {
    O('.parallax-image').each(function (el) {
        // don't run for touch
        if (O.Tests.touch) return;

        var img = el.firstElementChild,
            offset = 200,
            ticking = false,
            vpBottom, boxTop, scrollSpan;

        // traverse up to get parent section
        var parent = el.parentElement;
        do {
            if (parent.classList.contains('section-wrapper')) {
                var section = parent;
                parent = null;
            } else {
                parent = parent.parentElement;
            }
        } while (parent);


        var setSize = function () {
            scrollSpan = OW.Global.winHeight + el.offsetHeight;
            el.style.height = img.height - offset + 'px';
        };

        var setup = function () {
            setSize();
            update();
            O(window).on('scroll', onscroll);
            O(window).on('resize', setSize);
        };

        var update = function () {
            vpBottom = window.pageYOffset + OW.Global.winHeight;
            boxTop = el.getBoundingClientRect().top + window.pageYOffset;
            var percent = 1 - ((vpBottom - boxTop) / scrollSpan);

            if (percent >= 0 && percent <= 1) {
                img.style.top = -(percent * offset) + 'px';
            }
            ticking = false;
        };

        var onscroll = function () {
            if (!ticking && section.dataset.open == 'true') {
                ticking = true;
                window.requestAnimationFrame(update);
            }
        };

        // setup once we're sure image has loaded
        if (img.height) {
            setup();
        } else {
            O(img).on('load', setup);
        }
    });
};

/* "Card" slider (Sponsorships) */
OW.cardSlider = function (container) {
    // todo: this is a more general version of OW.setupCategoryList and they should be merged... when there's time.
    if (!container) return;

    var list = container.firstElementChild.firstElementChild;
    if (list && list.children.length > 4) {
        // set up list width
        var items = list.children.length,
            pages = Math.ceil(items / 4),
            current = 0,
            self = this;

        list.style.width = (items / 4 * 100) + '%';
        O(list.children).css('width', 100 / items + '%');

        // create navigation
        var prevEl = document.createElement('div');
        var nextEl = prevEl.cloneNode();
        prevEl.className = 'slideshow-arrow prev';
        nextEl.className = 'slideshow-arrow next';

        container.appendChild(prevEl);
        container.appendChild(nextEl);
        container.classList.add('first');

        // create pagination
        var pagination = OW.createPagination(pages);
        container.appendChild(pagination);


        var next = function () {
            if (this.current < this.pages - 1) OW.sponsorSlider.goTo(this.current + 1);
        };

        var prev = function () {
            if (this.current) OW.sponsorSlider.goTo(this.current - 1);
        };

        var goTo = function (i) {
            // unset current pagination
            this.nav[2].children[this.current].className = '';

            // update current
            this.current = i;

            // set page
            this.nav[2].children[this.current].className = 'current';

            // if it's first or last page, is there an offset?
            var offsetPer = 0;
            if (this.offset && (i == this.pages - 1)) offsetPer = 100 - (this.offset * 25);

            var newPercent = -(this.current * 100 - offsetPer);

            // set position
            if (O.Tests.transition) {
                // modern browsers <3
                list.style.left = newPercent + '%';
            } else {
                // developers, developers, developers...
                var currentPercent = list.style.left;
                if (!currentPercent) currentPercent = '0%';

                O(list).domAnimate('left', currentPercent, newPercent, 200);
            }

            list.parentElement.parentElement.classList.remove('first');
            list.parentElement.parentElement.classList.remove('last');
            if (!this.current) {
                // first page
                list.parentElement.parentElement.classList.add('first');
            } else if (this.current == this.pages - 1) {
                // last page
                list.parentElement.parentElement.classList.add('last');
            }
        };


        // keyboard nav
        var keys = function (e) {
            switch (e.which) {
                case 37:
                    OW.sponsorSlider.prev();
                    break;
                case 39:
                    OW.sponsorSlider.next();
                    break;
            }
        };

        // events
        O(pagination).on('click', function (e) {
            OW.sponsorSlider.goTo(O(e.target).index());
        });

        O(prevEl).on('click', function () {
            OW.sponsorSlider.prev();
        });
        O(nextEl).on('click', function () {
            OW.sponsorSlider.next();
        });
        O(document).on('keydown', keys);

        Hammer(list.parentElement).on("swipeleft swiperight", function (e) {
            if (e.type == 'swiperight') {
                OW.sponsorSlider.prev();
            } else {
                OW.sponsorSlider.next();
            }
        });

        // return functions
        return {
            current: current,
            pages: pages,
            list: list,
            nav: [nextEl, prevEl, pagination],
            offset: items % 4,

            next: next,
            prev: prev,
            goTo: goTo,

            destroy: function () {
                this.list.removeAttribute('style');
                O(this.list.children).css('width', 'auto');
                O(document).off('keydown', this.keys);
                O(this.nav).remove();
            },
            keys: keys
        };
    }
};

// checkout
OW.checkout = function () {
    // Voucher code toggle display
    $(document).on("click", ".voucher-code-toggle", function (e) {
        e.preventDefault();
        $(this).css('display', 'none').next().css('display', 'block');
    });

    var updateCheckout = function (data, url) {
        data = normalizeFormData(data);
        $.ajax({
            type: 'POST',
            data: data,
            url: url,
            success: function (html) {
                // update main content
                $("#ajax-wrapper").html(html);
                OW.customSelects();
            }
        });
    };

    var normalizeFormData = function (data) {
        // Form contend so data don't get lost when posting different forms
        // text inputs
        data += '&' + $('#submit_form input[type=text]').serialize();
        // alternative shipping switcher
        data += '&reg_use_shi=' + ($('input[name="reg_use_shi"]').is(':checked') ? '1' : '');
        // country selector (check if we need to post the paypal one instead)
        if ($('select[name="reg_cou_id"]').length) {
            data += '&reg_cou_id=' + $('select[name="reg_cou_id"]').val();
        }
        else {
            data += '&reg_cou_id=' + $('select[name="pp_cou_id"]').val();
        }
        // State selector
        if ($('select[name="reg_state"]').length) {
            data += '&reg_state=' + $('select[name="reg_state"]').val();
        }


        // Alternative shipping country and state
        if ($('select[name="reg_shi_cou_id"]').length) {
            data += '&reg_shi_cou_id=' + $('select[name="reg_shi_cou_id"]').val();
        }
        if ($('select[name="reg_shi_state"]').length) {
            data += '&reg_shi_state=' + $('select[name="reg_shi_state"]').val();
        }


        // News letter
        if ($('input[name="reg_send"]').length) {
            data += '&reg_send=' + ($('input[name="reg_send"]').is(':checked') ? '1' : '');
        }
        else {
            data += '&reg_send=' + ($('input[name="reg_send_pp"]').is(':checked') ? '1' : '');
        }

        // Ajax flag
        data += '&ajax=1';

        return data;
    };

    // Basket products (add/subtract/delete)
    $(document).on("click", "#basket_form :submit", function (e) {
        e.preventDefault();

        var input = $(this);
        var form = input.closest("form");
        var data = form.serialize();

        data += "&" + input.attr("name") + "=" + input.val();

        updateCheckout(data, form.attr("action"));
    });

    // Shipping Selector
    $(document).on("change", "#shipping_selector input[type=radio]", function (e) {
        e.preventDefault();

        $('#shipping_selector .option.checked').removeClass('checked');
        $(this).parents('.option').addClass('checked');

        var form = $('#' + $(this).attr('name'));
        var data = form.serialize();

        // this form won't post to /selection so we need to add
        // "additional_product" to force silk to output ajaxBasketList
        data += "&additional_product=1";

        updateCheckout(data, form.attr("action"));
    });

    // Voucher code
    $(document).on("submit", "#voucher_form", function (e) {
        e.preventDefault();

        var form = $(this);
        var data = form.serialize();

        updateCheckout(data, form.attr("action"));
    });

    // Payment methods
    $(document).on("change", 'input[name="inp_payment"]', function () {
        var input = $(this);
        input.parent().toggleClass('checked', input.checked).siblings().removeClass('checked');

        var form = $('#payment_form');
        $('input[name="pay_method"]', form).val(input.val());

        var data = form.serialize();

        updateCheckout(data, form.attr("action"));
    });

    // Use alternative shipping swithcer
    $(document).on("change", 'input[name="reg_use_shi"]', function () {

        var input = $(this);
        var form = $('#alternative_shipping');
        if (input.is(':checked')) {
            form.show();
        }
        else {
            form.hide();
        }

    });

    // Country (from billing options)
    $(document).on("change", 'select[name="reg_cou_id"], select[name="pp_cou_id"]', function () {
        // Do nothing if alternative shipping is enabled
        var switcher = $('input[name="reg_use_shi"]');
        if (switcher.is(':checked')) return;

        var input = $(this);
        var form = $('#shipping_form');
        $('input[name="cou_id"]', form).val(input.val());

        var data = form.serialize();

        updateCheckout(data, form.attr("action"));
    });

    // Country (from alternative shipping options)
    $(document).on("change", 'select[name="reg_shi_cou_id"]', function () {
        // Do nothing if alternative shipping is not enabled
        var switcher = $('input[name="reg_use_shi"]');
        if (!switcher.is(':checked')) return;

        var input = $(this);
        var form = $('#shipping_form');
        $('input[name="cou_id"]', form).val(input.val());

        var data = form.serialize();

        updateCheckout(data, form.attr("action"));
    });

    // Klarna autocomplete
    $(document).on("keypress", "#reg_pnr", function (e) {
        // don't post entire form on enter
        if (e.which == 13) {
            e.preventDefault();
            // but DO post it to klarna, by simulating
            // a click on the continue button
            $("#reg_pnr_autocomplete").click();
        }
    });
    $(document).on("click", "#reg_pnr_autocomplete", function (e) {
        e.preventDefault();
        var input = $('input[name="reg_pnr"]');
        var form = $('#personalnumber_form');

        $('input[name="pno"]', form).val(input.val());

        // post with ajax
        $.ajax({
            type: "POST",
            url: form.attr("action"),
            data: form.serialize(),
            success: function (json) {
                if (json.got) {
                    // found person! fill out the form
                    $("#reg_name").val(json.firstname).trigger('change');
                    $("#reg_sname").val(json.lastname).trigger('change');
                    $("#reg_address").val(json.address).trigger('change');
                    $("#reg_zipcode").val(json.zipcode).trigger('change');
                    $("#reg_city").val(json.city).trigger('change');

                    // and show it
                    $(".checkout-billing .hidden-form").slideDown(200);
                } else {
                    // no hit
                    input.closest("label").addClass("error");
                }
            },
            dataType: 'json'
        });

    });


    // remove error color on type
    $(document).on("change", "#ajax-wrapper .error input[type=text]", function (e) {
        $(this).closest(".error").removeClass("error");
        $(this).closest(".error-text").remove();
    });
};

OW.setStickyFooter = function () {
    // set section wrapper height to make footer stick to bottom
    var height = O('.site-footer').height() + 100;
    OW.Global.sectionContainer.css('min-height', window.innerHeight - height + 'px');
};

OW.childPageReadMore = function () {
    O('.child-page .more-link').on('mouseover', function (e) {
        e.preventDefault();
        var page = this.parentElement.parentElement.parentElement;
        page.classList.add('hover');
    });

    O('.child-page .more-link').on('mouseout', function (e) {
        e.preventDefault();
        var page = this.parentElement.parentElement.parentElement;
        page.classList.remove('hover');
    });

    O('.child-page .more-link').on('click', function (e) {
        e.preventDefault();
        var page = this.parentElement.parentElement.parentElement;
        page.classList.toggle('child-page-open');

        O(this.parentElement.parentElement.getElementsByClassName('full-content')).slideToggle();

        var tempText = this.innerHTML;
        this.innerHTML = page.getAttribute('data-close');
        page.setAttribute('data-close', tempText);
    });
};

OW.retailerFilter = function () {
    O('.retailer-page .filter select').on('change', function () {

        // find parent form
        var form;
        var el = this;
        var depth = 30;
        while (!form && depth > 0) {
            var parent = el.parentElement;
            if (!parent) {
                break;
            }
            if (parent.nodeName === 'FORM') {
                form = parent;
                break;
            }
            el = parent;
            depth--;
        }
        if (form) {
            form.submit();
        }

    });
};

OW.loadMore = function () {
    O('.load-more a').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var url = this.getAttribute('href');
        var loading = document.createElement('div');
        loading.className = "loading";
        var parent = O(this.parentElement);
        parent.append(loading);

        O(this).remove();

        O.Utils.get(url + '#load-more-container', function () {
            var page = document.createElement('div');
            page.innerHTML = this.responseText;
            var pageContainer = page.querySelectorAll('#load-more-container');
            parent.remove();
            if (pageContainer.length) {
                O('#load-more-container')[0].innerHTML += pageContainer[0].innerHTML;
                OW.ajaxLoadedSection();

                // google analytics
                var a = document.createElement('a');
                a.href = url;
                var path = a.pathname + a.search;
                if (typeof _gaq != 'undefined') _gaq.push(['_trackPageview', path]);
            }

        });
    });
};

OW.setDesktopDimensions = function (argument) {
    var set = function () {
        OW.Global.winHeight = window.innerHeight;
        OW.Global.winWidth = window.innerWidth;
        //	OW.Global.headerContent[0].style.padding = ((window.innerHeight / 4) - 160) + 'px 0';
    };
    set();

    O(window).on('resize', set);
};

OW.customSelects = function () {
    // customly css styled selects
    O('select.custom-select').each(function (el) {
        // wrap and hide select
        var wrap = document.createElement('div'),
            label = document.createElement('div');

        wrap.className = 'custom-select';
        wrap.appendChild(label);

        O(el).removeClass('custom-select').wrap(wrap).on('change', function (e) {
            var current = this.options[this.selectedIndex];

            // set selected label
            label.innerHTML = current.text;

            // is it a link menu? redirect to url
            if (this.dataset.links) {
                document.location = current.value;
            }
        });


        if (el.options.length) label.innerHTML = el.options[el.selectedIndex].text;
    });
};

OW.sectionChange = function () {
    // buttons
    $('.article-slider-buttons a').on('click', function (e) {
        e.preventDefault();

        // button class
        O('.article-slider-buttons a').addClass('disabled');
        O(this).removeClass('disabled');

        // section swap
        $('.section-content').addClass('section-content-hidden');
        $($(this).attr('href')).removeClass('section-content-hidden');

        // check for section 2
        if ($(this).attr('href') == '#section_2') {
            history.replaceState(undefined, undefined, '#2');
        } else {
            history.replaceState(undefined, undefined, '#');
        }
    });

    // url
    if (window.location.hash == '#2') {
        $('.article-slider-buttons a:nth-child(2)').trigger('click');
    }
};

OW.carouselCategories = function () {
    // homepage

    if ($('.watches-categories').length) {
        var config = {
            center: false,
            margin: 0,
            mouseDrag: false,
            nav: true,
            dots: false,
            autoWidth: true,

            items: 4,
            responsive: {
                1284: {
                    items: 5
                }
            }
        };

        var owl = $('.owl-carousel');
        owl.owlCarousel(config);
    }

};

OW.carouselModelList = function () {
    // watches page

    if ($('.watches-model-list').length) {
        var config = {
            center: false,
            margin: 0,
            mouseDrag: false,
            nav: true,
            dots: false,

            items: 1,
            responsive: {
                640: {
                    items: 3
                },
                960: {
                    items: 4
                }
            }
        };

        var owl = $('.owl-carousel');
        owl.owlCarousel(config);
    }

};

OW.carouselDestroy = function () {
    // destroy only on home
    if ($('.watches-categories').length) {
        var owl = $('.owl-carousel');
        owl.trigger('destroy.owl.carousel');
    }
};

OW.videoPlayer = function () {
    var players = document.querySelectorAll('.video-player');

    for (var i = 0; i < players.length; ++i) {
        players[i].addEventListener('click', play);
    }

    function play() {
        var videoURL = this.dataset.video;
        var video = '<iframe class="video-player" width="100%" frameborder="0" allowfullscreen src="' + videoURL + '"></iframe>';

        this.outerHTML = video;
    }
};

OW.newsletterPopup = function () {
    $(document).ready(init);

    modal = $('.modal');

    if (modal.length <= 0 && $('body').hasClass('no-scroll')) {
        $('body').removeClass('no-scroll')
    }

    function init() {
        onCloseClick();
        validateForm();

        var cookieCheck = document.cookie.indexOf('newsletterPopup');
        var languageCheck = window.location.href.indexOf('/de/');

        // if (cookieCheck == -1 && languageCheck > -1) {
        //     setTimeout(function () {
        //         //show();
        //     }, 5000);
        // }
    }

    function show() {
        $('.modal').addClass('modal--active');
        $('body').toggleClass('no-scroll');
    }

    function validateEmail(email) {
        var re = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
        return re.test(email);
    }

    function validateZipcode(zipcode) {
        var re = /[0-9a-zA-Z]{4,5}/;
        return re.test(zipcode);
    }

    function validateForm() {
        $('.signup-form').validate({
            rules: {
                email: {
                    required: true,
                    email: true
                },
                zipcode: {
                    required: true,
                    minlength: 5
                }
            },
            messages: {
                zipcode: {
                    required: "Bitte gib eine gültige PLZ ein.",
                    minlength: "Ihre Postleitzahl muss mindestens 5 Ziffern lang sein"
                },
                email: "Bitte geben Sie eine gültige E-Mail Adresse ein"
            },
            submitHandler: function (form, e) {
                e.preventDefault();
                var consentEl = $('.signup-form-consent');
                var label = $('.checkbox > label');

                var formData = {
                    'w-field-field-17681-99036-631254-email': $('input[name=email]').val(),
                    'w-field-field-17681-99036-631254-25': $('input[name=zipcode]').val()
                };

                if ($('#consent').is(":checked")) {
                    $('.error', '.signup-form-consent').remove();
                    label.removeClass('label-error');
                    consentEl.removeClass('consent-error');

                    $.ajax({
                        type: "POST",
                        data: formData,
                        url: 'https://app.mailjet.com/widget/iframe/2Edw/4Bb',
                        dataType: 'html',
                        beforeSend: function () {
                            $('.spinner').toggleClass('spinner--active');
                            $('.signup-form-submit').remove();
                        }
                    })
                        .done(function (data) {
                            console.log("done");
                        })
                        .fail(function (xhr, textStatus, errorThrown) {
                            $('.modal-content-inner').replaceWith('<h2 class="response-title">Vielen Dank für Ihre Registrierung. Bitte überprüfen Sie Ihr Postfach, um die Anmeldung abzuschließen.</h2>');
                        });

                } else {
                    label.addClass('label-error');
                    consentEl.addClass('consent-error');
                    $('<span class="error">Bitte akzeptieren Sie unsere Datenschutzerklärung.</span>').insertAfter('.content');
                }
            }
        });

    }

    function onCloseClick() {
        modal = $('.modal');

        if (modal.length >= 1) {
            $('.modal-close').on('click', function () {
                $('body').toggleClass('no-scroll');

                var date = new Date();
                var setCookie = 'newsletterPopup=' + date.toUTCString();
                var days = 0.8 * 60 * 60 * 1000 * 30;
                var expires = 'expires=' + new Date(date.getTime() + days).toUTCString();

                document.cookie = setCookie + ';' + expires + ';path=/';

                modal.removeClass('modal--active');
            });
        }
    }
};

/* Main initiator */
OW.init = function () {
    // "non-responsive"/global functionality
    if (O.Tests.touch) OW.Global.body.addClass('touch');
    if (document.querySelector('.main-sections .main-content') || document.querySelector('body.home')) {
        OW.sectionNavigation();
    }

    OW.topMenu();
    OW.customSelects();
    OW.setupProductPage();
    OW.childPageReadMore();
    OW.retailerFilter();
    OW.loadMore();
    OW.shareButtons();
    OW.checkout();
    OW.sectionChange();
    OW.modelList = new OW.setupModelList();
    OW.carouselModelList();
    OW.videoPlayer();
    OW.newsletterPopup();

    // respononsive video embeds
    fitVids('.html-embeds');

    if (O.Tests.matchMedia) {
        // responsive breakpoints
        window.mediaCheck({
            media: '(min-width: ' + (OW.Global.mobileBreakpoint + 1) + 'px)',
            entry: function () {
                if (OW.Global.responsiveState == 'mobile') {
                    // unset mobile

                    // reset header
                    O('.menu-group ul').each(function (el) {
                        el.removeAttribute('style');
                    });
                    OW.Global.headerContent[0].removeAttribute('style');

                    // reset model list
                    if (OW.modelList.reset) OW.modelList.reset();

                    // close language and shipping menu
                    OW.Global.langshipMenu.css('right', 'auto').slideUp();
                }

                OW.loadDesktop();

            },
            exit: function () {
                if (OW.Global.responsiveState == 'desktop') {
                    // unset desktop

                    // reset category and model list
                    if (OW.modelList.reset) OW.modelList.reset();
                    // if (OW.categoryList.destroy) OW.categoryList.destroy();
                    if (OW.sponsorSlider.destroy) OW.sponsorSlider.destroy();

                    // close language and shipping menu
                    OW.Global.langshipMenu.css('right', 'auto').slideUp();

                    // destroy carousel
                    OW.carouselDestroy();
                }

                if (!OW.Global.mobileLoaded) {
                    // first mobile setup
                    MBP.scaleFix();
                    MBP.hideUrlBarOnLoad();
                    OW.setupMobileMenu();
                    OW.setupFooter();

                    OW.Global.mobileLoaded = true;
                }

                // set global variable and body class
                OW.Global.responsiveState = 'mobile';
            }
        });

    } else {
        // IE9... we meet again
        OW.loadDesktop();
    }
};

OW.loadDesktop = function () {
    if (!OW.Global.desktopLoaded) {
        // first desktop setup
        OW.setupFooter();
        OW.Global.desktopLoaded = true;

        if (!O.Tests.touch) {
            OW.setDesktopDimensions();
            OW.stickyNavigation();
            OW.parallax();
        }

        // if a section is open
        if (document.querySelector('.section-wrapper.open')) {
            OW.Global.sectionOpen = true;
            OW.setStickyFooter();
            if (OW.updateStickies) OW.updateStickies();
        }
    }

    // every desktop setup
    // OW.categoryList = new OW.setupCategoryList();
    OW.sponsorSlider = new OW.cardSlider(document.querySelector('.featured-activities'));

    OW.carouselCategories();

    // set global variable and body class
    OW.Global.responsiveState = 'desktop';
};

// functions to run when section has been loaded via ajax
OW.ajaxLoadedSection = function (section) {
    OW.shareButtons();
    OW.childPageReadMore();
    OW.loadMore();
    if (OW.updateStickies) OW.updateStickies();
    fitVids('.html-embeds');

    switch (section) {
        case 0:
            // glasshutte
            OW.parallax();
            break;

        case 1:
            // watches
            // if(OW.Global.responsiveState == 'desktop') OW.categoryList = new OW.setupCategoryList();
            if (OW.Global.responsiveState == 'desktop') OW.carouselCategories();
            break;

        case 2:
            // sponsorship
            if (OW.Global.responsiveState == 'desktop') OW.sponsorSlider = new OW.cardSlider(document.querySelector('.featured-activities'));
            break;
    }
};

// run on dom ready
O(OW.init);
console.log('digthis');
//console
// jQuery(function ($) {
//     let toggleMobileMenu = function (e) {
//         $(this).next('ul').slideToggle();
//     };
//     if ($(window).width() <= 640) {
//         $(document).on('click', '.site-footer .meta-menu h6.title', toggleMobileMenu);
//     }
//
//     $(window).on('resize', function () {
//         if ($(window).width() <= 640) {
//             $(document).on('click', '.site-footer .meta-menu h6.title', toggleMobileMenu);
//         } else {
//             $(document).off('click', '.site-footer .meta-menu h6.title', toggleMobileMenu);
//         }
//
//     })
//
// });